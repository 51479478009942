import React from 'react';
import { svg } from './Spinner.data';
import { SpinnerContainer, SpinnerSVG, LoadedContent } from './Spinner.style';
import { ISpinner } from './Spinner.i';

export const Spinner = ({ size='sm', inverted=false, loading=true, children }: ISpinner) => {
  return (
    <React.Fragment>
      {loading
        && <SpinnerContainer size={size}>
          <SpinnerSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700" inverted={inverted}>
            <path id="cylinder" fill="none" stroke="black" strokeWidth="3" d={svg.outer} />
            <path id="bullet1" fill="none" stroke="black" strokeWidth="1" d={svg.inner1} />
            <path id="bullet2" fill="none" stroke="black" strokeWidth="1" d={svg.inner2} />
            <path id="bullet3" fill="none" stroke="black" strokeWidth="1" d={svg.inner3} />
            <path id="bullet4" fill="none" stroke="black" strokeWidth="1" d={svg.inner4} />
            <path id="bullet5" fill="none" stroke="black" strokeWidth="1" d={svg.inner5} />
            <path id="bullet6" fill="none" stroke="black" strokeWidth="1" d={svg.inner6} />
          </SpinnerSVG>
        </SpinnerContainer>
      }
      <LoadedContent isLoading={loading}>
        {children}
      </LoadedContent>
    </React.Fragment>
  );
};

export const SpinnerInline = ({ className, inverted=false }: ISpinner & {className: string}) => {
  return <SpinnerSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700" inverted={inverted} className={className}>
    <path id="cylinder" fill="none" stroke="black" strokeWidth="3" d={svg.outer} />
    <path id="bullet1" fill="none" stroke="black" strokeWidth="1" d={svg.inner1} />
    <path id="bullet2" fill="none" stroke="black" strokeWidth="1" d={svg.inner2} />
    <path id="bullet3" fill="none" stroke="black" strokeWidth="1" d={svg.inner3} />
    <path id="bullet4" fill="none" stroke="black" strokeWidth="1" d={svg.inner4} />
    <path id="bullet5" fill="none" stroke="black" strokeWidth="1" d={svg.inner5} />
    <path id="bullet6" fill="none" stroke="black" strokeWidth="1" d={svg.inner6} />
  </SpinnerSVG>
};