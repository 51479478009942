import React, { useRef } from 'react';
import styled from 'styled-components';
import { useToggleState } from '@react-stately/toggle';
import { useLabel } from '@react-aria/label';
import { useCheckbox } from '@react-aria/checkbox';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { AriaCheckboxProps } from '@react-types/checkbox';
import { useFocusRing } from '../../../useFocusRing';

export type SVGCheckboxAriaProps = React.PropsWithChildren<{
  className?: string,
  CheckboxSVG?: React.ComponentType,
  label?: string,
  fill?: string,
} & AriaCheckboxProps>;

export const SVGCheckboxAria = (props : SVGCheckboxAriaProps) => {
  const state = useToggleState(props);
  const ref = useRef(null);
  const { inputProps } = useCheckbox(Object.assign({ 'aria-label': props.label }, props), state, ref);
  const { labelProps, fieldProps } = useLabel(props);
  const { isFocusVisible, focusProps } = useFocusRing();

  const CheckboxSVG = props.CheckboxSVG || CheckboxSVGBase;
  return (
    <SVGCheckBoxAriaContainer className = {props.className}>
      <SVGCheckBoxAriaLabel { ...labelProps } >
        <VisuallyHidden>
          <SVGCheckboxAriaInput
            { ...inputProps }
            { ...fieldProps }
            { ...focusProps }
            ref = { ref }
          />
        </VisuallyHidden>
        <CheckboxSVG
          aria-hidden="true"
          isSelected = { state.isSelected }
          isFocusVisible = { isFocusVisible }
          fill={props.fill}
        />
        {props.label}
      </SVGCheckBoxAriaLabel>
    </SVGCheckBoxAriaContainer>
  );
};

type CheckboxSVGBaseProps = {
  isSelected?: boolean,
  isFocusVisible?: boolean,
  fill?: string,
};

export const CheckboxSVGBase = (props : CheckboxSVGBaseProps) => {
  return (
    <SVGCheckBoxSVG { ...props }>
      <SVGCheckBoxAriaBorderBox/>
      {props.isSelected && (
        <SVGCheckBoxCheckmark/>
      )}
      {props.isFocusVisible && (
        <SVGCheckBoxFocusBox/>
      )}
    </SVGCheckBoxSVG>
  );
};

export const SVGCheckBoxAriaContainer = styled.div``;
export const SVGCheckBoxAriaLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
export const SVGCheckboxAriaInput = styled.input``;

export const SVGCheckBoxAriaBorderBox = styled.rect``;
export const SVGCheckBoxCheckmark = styled.path``;
export const SVGCheckBoxFocusBox = styled.rect``;

export const SVGCheckBoxSVG = styled.svg<CheckboxSVGBaseProps>`
  margin-right: 0.25em;
  width: 20px;
  height: 20px;
  flex: 0 0 auto;

  ${SVGCheckBoxAriaBorderBox} {
    x: 2px;
    y: 2px;
    rx: 4px;
    ry: 4px;
    width: 16px;
    height: 16px;
    stroke-width: 1px;

    fill: ${(props) => props.isSelected ? props.theme.gold[50] : (props.fill || 'none')};
    stroke: ${(props) => props.isSelected ? 'none' : '#444f5b' };
  }

  ${SVGCheckBoxCheckmark} {
    transform: translate(5px, 5px);
    d: path('M3.788 9A.999.999 0 0 1 3 8.615l-2.288-3a1 1 0 1 1
      1.576-1.23l1.5 1.991 3.924-4.991a1 1 0 1 1 1.576 1.23l-4.712
      6A.999.999 0 0 1 3.788 9z');
    stroke: ${(props) => props.theme.black[70] };
  }

  ${SVGCheckBoxFocusBox} {
    x: 1px;
    y: 1px;
    rx: 4px;
    ry: 4px;
    width: 18px;
    height: 18px;
    fill: ${(props) => props.fill || 'none'};
    stroke: ${(props) => props.theme.gold[50] };
    stroke-width: 1px;
  }
`;

