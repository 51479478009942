import styled from 'styled-components';

import {
  RadioGroupAria, RadioGroupAriaProps,
} from '../BaseComponents/Inputs/RadioGroupAria';

type RadioGroupProps = RadioGroupAriaProps & {direction?: 'row'|'column'}

export const RadioGroup = styled(RadioGroupAria)<RadioGroupProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
`;
