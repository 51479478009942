import React from 'react';
import { FaMailBulk, FaPhone } from 'react-icons/fa';
import RIACLogo from 'images/riac-logo.png';

const MaintenancePage = () => {
  return (
    <div className="min-h-screen bg-zinc-900 text-white flex items-center justify-center p-4">
      <div className="max-w-2xl mx-auto text-center">
        <img src={RIACLogo} alt="RIA Logo" className="mx-auto mb-8 w-48" />

        <h1 className="text-3xl font-bold text-yellow-500 mb-4">
          Website Temporarily Unavailable
        </h1>

        <p className="mb-4">
          We're currently performing maintenance to enhance your auction experience.
        </p>

        <p className="font-semibold mb-6">
          Expected return: <span className="text-yellow-500">September 16, 2024 at 12:00 PM CST</span>
        </p>

        <p className="mb-4">For urgent inquiries, please contact us:</p>

        <div className="flex flex-col sm:flex-row justify-center items-center gap-4 mb-6">
          <a
            href="tel:+18002388022"
            className="flex items-center justify-center gap-2 bg-yellow-500 text-zinc-900 px-4 py-2 rounded-md font-bold hover:bg-yellow-600 transition-colors"
          >
            <FaPhone/>
            Call 1-800-238-8022
          </a>
          <a
            href="mailto:info@rockislandauction.com"
            className="flex items-center justify-center gap-2 bg-zinc-700 px-4 py-2 rounded-md hover:bg-zinc-600 transition-colors"
          >
            <FaMailBulk/>
            Email Us
          </a>
        </div>

        <p className="text-sm text-gray-400">
          Thank you for your patience and understanding.
        </p>
      </div>
    </div>
  );
};

export default MaintenancePage;
